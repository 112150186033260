import * as React from "react";
import Layout from "common/components/Layout";
import Seo from "components/seo";
import NewCustomer from "containers/NewCustomer";
import PartnerWrapper from "../partner.style";
import DougEcker from "common/assets/image/partners/iwa/doug-ecker.jpg";
import IWALogo from "common/assets/image/partners/iwa/iwa.png";

const CustomerIntakePage = ({ location }) => {
    return (
        <Layout location={location.pathname}>
            <Seo title="Integrity Wealth Advisors: ERC New Business Intake Form" />
            <PartnerWrapper>
                <div className="Partner-info">
                    <div className="contact-info">
                        <h3>Doug Ecker</h3>
                        <p className="license">Retirement Plan Specialist / CRPS®<br/>
                        (805) 339-0760 x118<br/>
                        doug@iwaplan.com<br/>
                        </p> 
                        <a href="https://iwaplan.com" target="iwa"><img src={IWALogo} /></a>
                    </div>
                    <div className="profile-image">
                        <img src={DougEcker} />
                    </div>
                </div>
            </PartnerWrapper>
            <NewCustomer referrer={"5lPWWNW0BhihG8"}/>
        </Layout>
    );
};

export default CustomerIntakePage;